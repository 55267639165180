import { Content } from "../../components/content/content";
import { Footer } from "../../components/footer/footer";
import { Nav } from "../../components/nav/nav";

import { Helmet } from "react-helmet";
import { Bkg } from "../../components/content/bkg";
import { t } from "../../utils/cms";
import { Link } from "react-router-dom";
import { AppRoute } from "../../routes";

export function CookiePolicyPage() {
  return (
    <>
      <Helmet>
        <title>{t('risks.Metadata.meta_title_key_risks')}</title>
        <meta name="description" content={t('risks.Metadata.meta_description_key_risks')} />
      </Helmet>

      <Nav />

      <Content className="static">
        <h2>Cookie Policy</h2>
        <div className="text-content">
          <p>
            At PropNerd, we use cookies to enhance your experience on our website, analyze site usage, and support our
            marketing efforts. This Cookie Policy explains what cookies we use, the purposes they serve, and how you can
            manage your cookie preferences.
          </p>

          <h3>What Are Cookies?</h3>
          <p>
            Cookies are small data files stored on your device when you visit a website. They help improve the user
            experience, track analytics, and remember your preferences.
          </p>

          <h3>Types of Cookies We Use</h3>

          <h4>1. Strictly Necessary Cookies</h4>
          <p>
            These cookies are essential for the website to function correctly and cannot be turned off in our systems.
            They are usually set only in response to actions made by you, such as setting privacy preferences, logging
            in, or filling out forms.
          </p>

          <h4>2. Performance and Analytics Cookies</h4>
          <p>
            These cookies allow us to analyze web traffic, track user interactions on our site, and identify areas for
            improvement. They help us understand which pages are most popular and how users navigate through our website.
          </p>

          <h4>3. Functional Cookies</h4>
          <p>
            Functional cookies enable the website to provide enhanced functionality and personalization. These may be set
            by us or third-party providers whose services we have added to our pages.
          </p>

          <h4>4. Advertising and Marketing Cookies</h4>
          <p>
            These cookies may be set through our site by our advertising partners. They may be used to build a profile of
            your interests and show relevant ads on other sites.
          </p>

          <h3>Specific Cookies We Use</h3>
          <table>
            <thead>
              <tr>
                <th>Cookie Name</th>
                <th>Purpose</th>
                <th>Domain</th>
                <th>Category</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>_vwo_uuid_v2</td>
                <td>Visual Website Optimizer for A/B testing</td>
                <td>propnerd.io</td>
                <td>Performance</td>
              </tr>
              <tr>
                <td>i18next</td>
                <td>Language preference cookie</td>
                <td>propnerd.io</td>
                <td>Functional</td>
              </tr>
              <tr>
                <td>intercom-device-id</td>
                <td>Device tracking for Intercom Messenger</td>
                <td>propnerd.io</td>
                <td>Functional</td>
              </tr>
              <tr>
                <td>intercom-id</td>
                <td>User ID for Intercom messaging</td>
                <td>propnerd.io</td>
                <td>Functional</td>
              </tr>
              <tr>
                <td>intercom-session</td>
                <td>Session ID for Intercom messaging</td>
                <td>propnerd.io</td>
                <td>Functional</td>
              </tr>
              <tr>
                <td>cookie_consent</td>
                <td>Cookie consent preference</td>
                <td>propnerd.io</td>
                <td>Functional</td>
              </tr>
            </tbody>
          </table>

          <h3>Managing Your Cookie Preferences</h3>
          <p>
            Most browsers allow you to manage your cookie preferences through their settings. You can choose to accept or
            reject cookies or set your browser to notify you when a cookie is being placed on your device. Please note
            that blocking some types of cookies may impact your experience on the site.
          </p>

          <h3>Changes to This Cookie Policy</h3>
          <p>
            We may update this Cookie Policy from time to time to reflect changes in our practices or for other operational,
            legal, or regulatory reasons. The updated policy will be posted on this page with the effective date.
          </p>

          <p>
            For further information about our use of cookies or to contact us with questions, please refer to our{' '}
            <Link to={AppRoute.Privacy}>Privacy Policy</Link> or reach out to us at{' '}
            <a href="mailto:info@propnerd.io">info@propnerd.io</a>.
          </p>

          <p>By using our website, you agree to our use of cookies as described in this policy.</p>
        </div>

      </Content >

      <Footer />
    </>

  );
}