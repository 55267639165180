import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MailIcon from '../../../assets/img/sign-up/mail-fast.svg';
import CallIcon from '../../../assets/img/sign-up/call-out.svg';
import { MUTATION_SEND_OTP, MUTATION_VERIFY_OTP } from '../../../graphql/mutations/user';
import UnderMaintenance from '../../../components/modal/under-maintenance';
import "../../../assets/styles/verify-otp.css"


export function VerifyOTP({ onStepChange, currentStep }: { onStepChange: (step: number) => void, currentStep: number }) {
    const navigate = useNavigate();
    const location = useLocation();

    const [infoMessage, setInfoMessage] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [verificationCode, setVerificationCode] = useState<string>('');
    const [verifyOtp, { loading: verifyOtpLoading, error }] = useMutation(MUTATION_VERIFY_OTP, { errorPolicy: 'all' });
    const [sendOtp, { loading: sendOtpLoading, error: sendOtpError }] = useMutation(MUTATION_SEND_OTP, { errorPolicy: 'all' });
    const [deviceId, setDeviceId] = useState<string>('');
    const [otpSent, setOtpSent] = useState<boolean>(false);
    const [otpMethod, setOtpMethod] = useState<'phone' | 'email' | null>('email');
    const [userId, setUserId] = useState<string>('');
    const [countdown, setCountdown] = useState<number>(0);
    const [isResendDisabled, setIsResendDisabled] = useState<boolean>(true);

    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    async function resendLink() {
        setInfoMessage('Resending OTP. Please wait...');
        setErrorMessage('');
        await sendOtp({ variables: { userId, deviceId, method: otpMethod } });
        setInfoMessage('OTP sent successfully');
        setErrorMessage('');
        // Reset countdown and disable the resend button
        setCountdown(30);
        setIsResendDisabled(true);
    }

    useEffect(() => {
        const deviceIdToSet = id || localStorage.getItem('deviceId') || '';
        const userIdToSet = localStorage.getItem('userId') || '';

        if (!deviceIdToSet || !userIdToSet) {
            console.error("Inaccurate data for registration as deviceId: " + deviceId + " and userId: " + userId)
        } else {
            setDeviceId(deviceIdToSet);
            setUserId(userIdToSet);
        }
    }, [location.search, navigate]);

    async function onSubmit(e: FormEvent) {
        e.preventDefault();
        setInfoMessage('Verifying OTP. Please wait...');
        setErrorMessage('');

        const { data } = await verifyOtp({ variables: { userId, deviceId, otp: verificationCode, method: otpMethod } });
        if (data) {
            localStorage.setItem("email", data.verifyOTP.user.email);
            localStorage.setItem("token", data.verifyOTP.token);
            localStorage.setItem("lastLogin", "");
            localStorage.setItem("deviceId", deviceId);
            sessionStorage.setItem("firstLoginShown", "false");
            setInfoMessage('OTP verified successfully');
            setErrorMessage('');
            setOtpSent(false);
            onStepChange(currentStep + 1);

        } else {
            setErrorMessage('OTP verification failed');
            setInfoMessage('');
        }
    }

    useEffect(() => {
        if (countdown === 0) {
            setIsResendDisabled(false);
            return;
        }
        const timer = setTimeout(() => {
            setCountdown(countdown - 1);
        }, 1000);

        return () => clearTimeout(timer); // Cleanup the timer
    }, [countdown]);

    async function handleOtpMethod(method: 'phone' | 'email' | null) {
        setInfoMessage(`Sending OTP to your ${method}. Please wait...`);
        setErrorMessage('');

        try {
            const response = await sendOtp({ variables: { userId, deviceId, method } });
            if (response.errors) {
                setErrorMessage(response.errors[0].message);
            } else {
                setInfoMessage(`OTP sent to your ${method}. Please check your inbox or messages.`);
                setOtpSent(true);
                setCountdown(30);
            }
        } catch (error: any) {
            setErrorMessage(error.message);
        }
    }

    const handleCloseUnderMaintenance = () => {
        setErrorMessage('');
    }

    return (
        <section className="">
            <UnderMaintenance
                errorType={errorMessage}
                onClose={handleCloseUnderMaintenance}
            />
            {!otpSent ? (
                <div className="flex flex-column items-center w-1/2 max-w-md px-8 pt-0 pb-12 mx-auto ">
                    <div className="flex options-container ">
                        <div className={`otp-option ${otpMethod === "email" ? 'selected' : ''}`} onClick={() => setOtpMethod("email")}
                        >
                            <div className='flex icons-container'>
                                <img src={MailIcon} alt="email" className="otp-icon" />
                                <span className={`circle ${otpMethod === "email" ? 'selected' : ''}`}></span>
                            </div>
                            <div data-test='email-otp' className='otp-option-content'>
                                <p className={`option-title ${otpMethod === "email" ? 'selected' : ''}`}>Via Email</p>
                                <p className='option-subtitle'>Get your OTP sent to your registered email.</p>
                            </div>
                        </div>
                        <div className={`otp-option ${otpMethod === "phone" ? 'selected' : ''}`} onClick={() => setOtpMethod("phone")}>
                            <div className='flex icons-container'>
                                <img src={CallIcon} alt="phone" className="otp-icon" />
                                <span className={`circle ${otpMethod === "phone" ? 'selected' : ''}`}></span>
                            </div>
                            <div data-test='sms-otp' className='otp-option-content'>
                                <p className={`option-title ${otpMethod === "phone" ? 'selected' : ''}`}>Via Phone</p>
                                <p className='option-subtitle'>Get your OTP sent to your phone via SMS.</p>
                            </div>
                        </div>
                    </div>
                    {errorMessage && errorMessage !== 'UNDER_MAINTENANCE' && <div className="error-message">{errorMessage}</div>}
                    <button data-test="send-code" className="btn btn-primary btn-square btn-full-width mt-5 mb-2" type="button" onClick={() => handleOtpMethod(otpMethod)} style={{ height: '3rem' }} disabled={sendOtpLoading}>
                        {sendOtpLoading ? "Sending..." : "Send Code"}
                    </button>
                </div>
            ) : (
                <form className="form-container" onSubmit={onSubmit}>
                    {infoMessage && <div className="info-message">{infoMessage}</div>}
                    {errorMessage && errorMessage !== 'UNDER_MAINTENANCE' && <div className="error-message">{errorMessage}</div>}
                    <div className="form-group">
                        <label className="otp-form-label">Verification code</label>
                        <input
                            className="form-input"
                            data-test="verification-code"
                            autoFocus
                            type="text"
                            value={verificationCode}
                            placeholder="Enter your verification code"
                            onChange={(e) => { setVerificationCode(e.target.value); setErrorMessage(''); }}
                        />
                    </div>


                    {countdown > 0 && (
                        <div className="resend-timer">
                            <h5 className="timer-text">Resend available in</h5>
                            <h5 data-test="countdown" className="timer-count">00:{countdown}</h5>
                        </div>
                    )}

                    <div className="button-group">
                        <button
                            type="submit"
                            data-test="verify-btn"
                            className="btn btn-primary btn-square"
                            disabled={verifyOtpLoading}
                        >
                            Verify
                        </button>
                        <button
                            className="btn btn-secondary btn-square"
                            type="button"
                            data-test="resend-btn"
                            onClick={resendLink}
                            disabled={isResendDisabled}
                        >
                            Resend link
                        </button>
                    </div>
                </form>
            )}
        </section>
    );
}
