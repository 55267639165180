import { Helmet } from "react-helmet";
import { Content } from "../../../components/content/content";
import { Footer } from "../../../components/footer/footer";
import { Nav } from "../../../components/nav/nav";

import { gql, useMutation } from "@apollo/client";
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { useEffect, useState } from "react";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import { Bkg } from "../../../components/content/bkg";
import { t } from "../../../utils/cms";
import UnderMaintenance from "../../../components/modal/under-maintenance";


export function PropertySubmitPage() {

  const { isLoaded: isGoogleApiLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
    libraries: ['places', 'marker'],
  });

  let addressAutocomplete: google.maps.places.Autocomplete;

  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [lat, setLat] = useState<number>();
  const [lng, setLng] = useState<number>();
  const [bedrooms, setBedrooms] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('+44');
  const [submitted, setSubmitted] = useState(false);
  const [infoMessage, setInfoMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const [submitProperty, { data, error }] = useMutation(gql`mutation submitProperty($address:String!, $city:String!, $bedrooms:Int!, $firstName:String!, $lastName:String!, $email:String!, $phone:String!, $lat:Float!, $lng:Float!) {
    submitProperty(data:{address:$address,city:$city,bedrooms:$bedrooms,firstName:$firstName,lastName:$lastName,email:$email,phone:$phone,lat:$lat,lng:$lng}) {
      id
    }
  }`, { errorPolicy: 'all' })

  useEffect(() => {
    setErrorMessage(error?.message);
  }, [error]);

  useEffect(() => {
    if (data) {
      setInfoMessage('Your property has been submitted successfully. You will be sent a confirmation email soon.');
      setSubmitted(true);
    }
  }, [data]);

  function onMapLoad(autocomplete: google.maps.places.Autocomplete) {
    addressAutocomplete = autocomplete;
  }

  function onPlaceChanged() {
    if (!addressAutocomplete) return;
    const place = addressAutocomplete.getPlace();
    const formattedAddress = place.formatted_address;
    if (!formattedAddress) return;
    setAddress(formattedAddress);
    setCity(getCity(place));
    setLat(place.geometry?.location?.lat())
    setLng(place.geometry?.location?.lng())
  }

  function getCity(place: google.maps.places.PlaceResult) {
    const city = place.address_components?.find(c => c.types.includes("postal_town"));
    return city!.long_name;
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setInfoMessage(undefined);
    setErrorMessage(undefined);
    setSubmitted(false);

    setInfoMessage('Processing your request. Please wait...');

    // Submit form
    const data = {
      address,
      city,
      bedrooms,
      firstName,
      lastName,
      phone,
      email,
      lat,
      lng,
    };

    if (!address || !firstName || !lastName || !phone || !email) {
      setInfoMessage(undefined);
      setErrorMessage('Please fill in all fields');
      return;
    }

    try {
      setErrorMessage(undefined);
      submitProperty({ variables: data });
    } catch (error) {
      console.error('Error:', error);
      setInfoMessage(undefined);
    }
  }

  const handleCloseUnderMaintenance = () => {
    setErrorMessage('');
  }

  return (
    <>
      <Helmet>
        <title>{t('submit.meta information.meta_title_submit')}</title>
        <meta name="description" content={t('submit.meta information.meta_description_submit')} />
      </Helmet>

      <UnderMaintenance
        errorType={errorMessage}
        onClose={handleCloseUnderMaintenance}
      />

      <Nav />

      <Bkg type="bottom" />

      <Content className="submit">
        <h2>{t('submit.page information.page_title_submit')}</h2>
        <p>{t('submit.page information.page_description_submit')}</p>
        <form className="flex flex-1 mb-5 flex-column gap" onSubmit={handleSubmit}>
          <h3>{t('submit.form details.form_title_submit')}</h3>
          {!submitted && (
            <>
              <div className="flex flex-column">
                <label htmlFor="address">{t('submit.form details.address_label_submit')}</label>
                {isGoogleApiLoaded && (
                  <Autocomplete
                    options={{ componentRestrictions: { country: 'uk' } }}
                    onLoad={onMapLoad}
                    onPlaceChanged={onPlaceChanged}
                  >
                    <input
                      type="text"
                      id="address"
                      name="address"
                      placeholder={t('submit.form details.address_placeholder_submit')}
                    />
                  </Autocomplete>
                )}
              </div>
              <div className="flex flex-column">
                <label htmlFor="bedrooms">{t('submit.form details.bedrooms_label_submit')}</label>
                <select id="bedrooms" name="bedrooms" value={bedrooms} onChange={(e) => setBedrooms(parseInt(e.currentTarget.value))}>
                  <option value="0">{t('submit.form details.bedroom_options_studio_submit')}</option>
                  <option value="1">{t('submit.form details.bedroom_options_1_submit')}</option>
                  <option value="2">{t('submit.form details.bedroom_options_2_submit')}</option>
                  <option value="3">{t('submit.form details.bedroom_options_3_plus_submit')}</option>
                </select>
              </div>
              <div className="flex flex-column">
                <label htmlFor="firstName">{t('submit.form details.first_name_label_submit')}</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={firstName}
                  placeholder={t('submit.form details.first_name_placeholder_submit')}
                  onChange={(e) => setFirstName(e.currentTarget.value)}
                />
              </div>
              <div className="flex flex-column">
                <label htmlFor="lastName">{t('submit.form details.last_name_label_submit')}</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={lastName}
                  placeholder={t('submit.form details.last_name_placeholder_submit')}
                  onChange={(e) => setLastName(e.currentTarget.value)}
                />
              </div>
              <div className="flex flex-column">
                <label htmlFor="email">{t('submit.form details.email_label_submit')}</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  placeholder={t('submit.form details.email_placeholder_submit_property')}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                />
              </div>
              <div className="flex flex-column">
                <label htmlFor="phone">{t('submit.form details.phone_label_submit_property')}</label>
                <PhoneInputWithCountrySelect
                  defaultCountry="GB"
                  placeholder={t('submit.form details.phone_placeholder_submit_property')}
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={(e) => setPhone(e ? e.toString() : '')}
                />
              </div>

              {infoMessage && <div className="info-message">{infoMessage}</div>}
              {errorMessage && errorMessage !== 'UNDER_MAINTENANCE' && <div className="error-message">{errorMessage}</div>}

              <div className="actions">
                <button type="submit" className="btn btn-primary btn-square">
                  {t('submit.form buttons.submit_button_text_submit_property')}
                </button>
              </div>
            </>
          )}
        </form>
      </Content>

      <Footer />
    </>

  );
}