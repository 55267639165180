import { Content } from "../../../components/content/content";
import { Nav } from "../../../components/nav/nav";

import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import snsWebSdk from '@sumsub/websdk';
import { useLocation } from "react-router-dom";
import { QUERY_GET_SUMSUB_WEB_SDK_LINK } from "../../../graphql/queries/sumsub-link";
import { useLazyQuery } from "@apollo/client";
import { t } from "../../../utils/cms";
import UnderMaintenance from "../../../components/modal/under-maintenance";


enum ViewState {
  Verify,
  Cancel,
  Success,
}

export function AccountVerifyPage() {

  const query = useQuery();

  const email = query.get('email');
  const userId = query.get('uid');
  const [iframeUrl, setIframeUrl] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [getSumsubWebSdkLink, { data, loading, error }] = useLazyQuery(QUERY_GET_SUMSUB_WEB_SDK_LINK, {
    fetchPolicy: 'network-only',
  });


  useEffect(() => {
    if (userId) {
      getSumsubWebSdkLink({ variables: { userId } });
    }
  }, [userId, getSumsubWebSdkLink]);

  useEffect(() => {
    if (data && data.sumsubWebSdkLinkFromParams) {
      setIframeUrl(data.sumsubWebSdkLinkFromParams);
    }
  }, [data]);
  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
    }
  }, [error]);

  const handleCloseUnderMaintenance = () => {
    setErrorMessage('');
  }


  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const [viewState, setViewState] = useState(ViewState.Verify);

  // function launchWebSdk(accessToken: string, applicantEmail: string, applicantPhone: string) {
  //   let snsWebSdkInstance = snsWebSdk
  //     .init(accessToken, getNewAccessToken)
  //     .withConf({
  //       lang: "en", //language of WebSDK texts and comments (ISO 639-1 format)
  //       email: applicantEmail,
  //       phone: applicantPhone,
  //     })
  //     .withOptions({ addViewportTag: false, adaptIframeHeight: true })
  //     // see below what kind of messages WebSDK generates
  //     .on("idCheck.onStepCompleted", (payload) => {
  //       console.log("onStepCompleted", payload);
  //     })
  //     .on("idCheck.onError", (error) => {
  //       console.log("onError", error);
  //     })
  //     .build();

  //   // you are ready to go:
  //   // just launch the WebSDK by providing the container element for it
  //   snsWebSdkInstance.launch("#sumsub-websdk-container");
  // }

  // useEffect(() => {



  // }, []);

  // async function getNewAccessToken() {
  //   return '';
  // }

  return (
    <>
      <Helmet>
        <title>{t('accountVerify.Helmet Information.helmet_title_account_verify')}</title>
        <meta
          name={t('accountVerify.Helmet Information.helmet_meta_name_account_verify')}
          content={t('accountVerify.Helmet Information.helmet_meta_content_account_verify')}
        />
      </Helmet>

      <UnderMaintenance
        errorType={errorMessage}
        onClose={handleCloseUnderMaintenance}
      />

      <Nav />

      <Content className="flex flex-column p-4">
        {iframeUrl && (
          <iframe
            src={iframeUrl}
            title={t('accountVerify.Iframe Settings.iframe_title_account_verify')}
            width="100%"
            height={800}
            style={{ border: 'none' }}
          ></iframe>
        )}
        {viewState === ViewState.Success && (
          <div className="alert success-message" role="alert">
            <h4 style={{ textAlign: 'center' }}>
              {t('accountVerify.Verification Success.success_message_heading_account_verify')}
            </h4>
            <p>{t('accountVerify.Verification Success.success_message_description_account_verify')}</p>
          </div>
        )}
      </Content>
    </>

  );
}
