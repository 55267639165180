import { Content } from "../../components/content/content";
import { Footer } from "../../components/footer/footer";
import { Nav } from "../../components/nav/nav";

import { Helmet } from "react-helmet";
import { Bkg } from "../../components/content/bkg";
import { t } from "../../utils/cms";

export function RisksPage() {
  return (
    <>
      <Helmet>
        <title>{t('risks.Metadata.meta_title_key_risks')}</title>
        <meta name="description" content={t('risks.Metadata.meta_description_key_risks')} />
      </Helmet>

      <Nav />

      <Content className="static">
        <h2>Risk Information</h2>
        <div className="text-content">
          <p>Due to potential for losses, the Financial Conduct Authority (FCA) considers this investment to be high risk.</p>
          
          <h3>What are the key risks?</h3>
          <p><b>You could lose all the money you invest</b></p>
          <p>If the property development you are investing in fails, there is a high risk that you will lose your money. Most start-up and early-stage property projects fail, and your investment is not guaranteed to succeed.</p>

          <h3>Advertised rates of return aren't guaranteed</h3>
          <p>This is not a savings account. If the property developer or business doesn't pay you back as agreed, you could earn less money than expected. A higher advertised rate of return means a higher risk of losing your money. If it looks too good to be true, it probably is.</p>

          <h3>You are unlikely to be protected if something goes wrong</h3>
          <p>The business offering this investment is not regulated by the FCA. Protection from the Financial Services Compensation Scheme (FSCS) only applies to claims against failed regulated firms. Learn more about FSCS protection <a href="https://www.fscs.org.uk/what-we-cover/investments" target="_blank">here</a>.</p>

          <h3>You are unlikely to get your money back quickly</h3>
          <p>Many property investments last for several years, so you should be prepared to wait for your money to be returned even if the project or business repays on time.</p>
          <p>You are unlikely to be able to cash in your investment early by selling your investment share. You are usually locked in until the project or business has paid you back over the period agreed.</p>

          <h3>Don't put all your eggs in one basket</h3>
          <p>Putting all your money into a single property project or investment type is risky. Spreading your money across different investments makes you less dependent on any one project to perform well.</p>
          <p>A good rule of thumb is not to invest more than 10% of your money in high-risk investments.</p>

          <p><b>If you are interested in learning more about how to protect yourself, visit the FCA's website here. (<a href="https://www.fca.org.uk" target="_blank">fca.org.uk</a>)</b></p>
        </div>
      </Content>

      <Footer />
    </>

  );
}