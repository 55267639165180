import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppRoute } from "../../../routes";

interface Props {
  onHide: () => void;
}

export function HomeDisclaimer({ onHide }: Props) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const agreed = localStorage.getItem('disclaimer-agreed');
    if (!agreed) {
      setTimeout(() => {
        setShow(true);
      }, 1000);
    }
  }, []);

  function close() {
    localStorage.setItem('disclaimer-agreed', 'true');
    setShow(false);
    onHide();
  }

  return (
    <>
      <div className={`modal-overlay ${show ? "show" : ""}`}></div>
      <div className={`disclaimer ${show ? "show" : ""}`}>
        <h2>Disclaimer</h2>
        <div className="separation-line"></div>
        <div className="content">
          <p>PropNerd is not authorised or regulated by the Financial Conduct Authority (FCA) and clients who proceed to invest will not have access to the Financial Ombudsman Service (FOS) or the financial services compensation scheme. Investors should seek advice from a person who specialises in advising on speculative, illiquid securities.</p>
          <p>PropNerd is not a broker-dealer, and all investments are subject to regulatory review. Investors are required to undergo identity verification through KYC processes and comply with AML laws. Failure to do so may result in account restrictions. This process is essential to ensure the security and legality of all transactions on the platform.</p>
          <p>Investing involves risks, including loss of capital, illiquidity, lack of dividends and dilution, and should be done only as part of a diversified portfolio. Investments should only be made by investors who understand these risks. Do not invest unless you are prepared to lose all the money you invest. This is a high-risk investment and you are unlikely to be protected if something goes wrong.</p>
          <p>Therefore, we can only deal with investors who are sufficiently knowledgeable and experienced in dealing with these types of investments and are classified as either a Sophisticated or High Net Worth Investor.</p>
          <p>Investors who are not ordinarily resident in the United Kingdom should ensure that they comply with the laws of their local jurisdiction before investing.</p>
          <p>If you do not meet these criteria, you must NOT take any further action and leave this site immediately. Please read our <Link to={AppRoute.Disclaimer} target="_blank">Disclaimer</Link> for more details.</p>
        </div>
        <div className="separation-line"></div>
        <button type="submit" className="btn btn-primary register-button" onClick={close}>Yes, I understand</button>
      </div>
    </>
  );
}
