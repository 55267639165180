import { useEffect } from "react";
import "react-phone-number-input/style.css";
import Cms_values from "../../../assets/cms-data.json";
import { ReactComponent as ImgAppStore } from '../../../assets/img/common/app-store.svg';
import { ReactComponent as ImgPlayStore } from '../../../assets/img/common/play-store.svg';
import ImgComingSoon from "../../../assets/img/home/coming_soon.png";
import i18n from "../../../i18n";
import ImgLogo from "./../../../assets/img/common/logo_white.svg";
import { Link } from "react-router-dom";

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
}

export function HomeGetStartedPopup({ show = false, setShow }: Props) {

  useEffect(() => {

    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        setShow(false);
      }
    });
  }, []);

  function t(key: string) {
    if (!key) {
      return key;
    }
    const [pageKey, sectionKey, valueKey] = key.split(".");

    if (!pageKey || !sectionKey || !valueKey) {
      return key;
    }

    const detectedLanguage = i18n.language.split("-")[0]; // or this.lang
    const valueObj: any = Cms_values.values.find(
      (v: any) =>
        v.page === pageKey &&
        v.section === sectionKey &&
        v.key === valueKey &&
        v.language === detectedLanguage
    );
    return valueObj ? valueObj.value : key;
  }


  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  function validateEmail(emailTest: string) {
    return emailRegex.test(emailTest);
  }


  // Extract UTM parameters from URL
  function getUtmParams() {
    const urlParams = new URLSearchParams(window.location.search);
    return {
      utm_campaign: urlParams.get("utm_campaign") || undefined,
      utm_medium: urlParams.get("utm_medium") || "Website",
      utm_source: urlParams.get("utm_source") || "Coming Soon",
      utm_content: urlParams.get("utm_content") || undefined,
      utm_term: urlParams.get("utm_term") || undefined,
      utm_id: urlParams.get("utm_id") || undefined,
    };
  }

  return (
    <>
      <div
        className={`modal-overlay ${show ? "show" : ""}`}
        onClick={() => {
          setShow(false);
        }}
      ></div>
      <div className={`coming-soon ${show ? "show" : ""}`}>
        <div className="logo">
        <img
          src={ImgLogo}
          alt="PropNerd"
          width={160}
          height={40}
        />
        </div>
        <img
          className="coming-soon-img"
          src={ImgComingSoon}
          alt="Coming Soon"
          width={550}
          height={300}
        />
        <h2>Get Started</h2>
        <p>
          {t("homeCommingSoon.Coming soon.coming_soon_description")}
          <br></br> {t("homeCommingSoon.Coming soon.coming_soon_description2")}
        </p>
        <button
          className="btn btn-close"
          onClick={() => {
            setShow(false);
          }}
        >
          X
        </button>
        <div className="separation-line"></div>
        <div className="content">
          <div className="store-links m-justify-center flex gap-lg">
            <Link to="/account/register" className="btn btn-primary animate__animated animate__backInLeft animate__delay-2s">Request more information</Link>
          </div>
          <h4 className="form-heading">
            Or download the App
          </h4>
          <p>Experience the full power of our platform.</p>
          <div className="store-links m-justify-center flex gap-lg">
            <a href="https://apps.apple.com/app/propnerd/id6497331931" target='_blank' className="animate__animated animate__backInLeft animate__delay-2s">
              <ImgAppStore width={150} height={50}  />
            </a>
            <a href="https://play.google.com/store/apps/details?id=io.propnerd.investor" target='_blank' className="animate__animated animate__backInLeft animate__delay-2s">
              <ImgPlayStore width={158} height={50} className="animate__animated animate__backInLeft animate__delay-2s"  />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
