import React, { createContext, useContext, useEffect, useState, PropsWithChildren } from 'react';
import { gql, useQuery } from '@apollo/client';
import UnderMaintenance from '../components/modal/under-maintenance';

const GET_SETTINGS = gql`
  query GetSettings($appVersion: String!) {
    settings(appVersion: $appVersion) {
      appVersion
      settings
    }
  }
`;

interface Settings {
  blurProperties?: boolean;
}

interface SettingsContextValue {
  settings: Settings | null;
  loading: boolean;
  error: any;
}

const defaultValue: SettingsContextValue = {
  settings: null,
  loading: true,
  error: null,
};

const SettingsContext = createContext<SettingsContextValue>(defaultValue);

interface SettingsProviderProps {
  appVersion: string;
}

export const SettingsProvider: React.FC<PropsWithChildren<SettingsProviderProps>> = ({ children, appVersion }) => {
  const { data, loading, error } = useQuery(GET_SETTINGS, {
    variables: { appVersion },
  });
  const [errorMessage, setErrorMessage] = useState('');

  const settings = data?.settings?.settings || null;

  const handleCloseUnderMaintenance = () => {
    setErrorMessage('');
  }

  useEffect(() => {
    if (error) {
      setErrorMessage(error.message);
    }
  }, [error]);

  return (
    <SettingsContext.Provider value={{ settings, loading, error }}>
      {errorMessage &&
        <UnderMaintenance
          errorType={errorMessage}
          onClose={handleCloseUnderMaintenance}
        />
      }
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => useContext(SettingsContext);
