import { Content } from "../../components/content/content";
import { Footer } from "../../components/footer/footer";
import { Nav } from "../../components/nav/nav";

import { Helmet } from "react-helmet";
import { AppRoute } from "../../routes";
import { Link } from "react-router-dom";

export function CompliancePage() {
  return (
    <>
      <Helmet>
        <title>PropNerd | Compliance</title>
        <meta
          name="description"
          content="Compliance for PropNerd. Please read the terms of service and compliance before using the website."
        />
      </Helmet>

      <Nav />

      <Content className="static">
        <h2>Compliance</h2>

        <div className="text-content">

          <h1>Compliance and Investor Protection</h1>
          <p>
            At <strong>PropNerd</strong>, we prioritise the safety and security of our
            users and investments. We strictly follow industry regulations and compliance
            standards to ensure all transactions and interactions on our platform are
            fully compliant with applicable laws. Below are our key compliance policies
            and measures.
          </p>
          <h3>KYC (Know Your Customer) Policy</h3>
          <p>
            We are committed to verifying the identity of all users to protect against
            fraudulent activities. As part of our <strong>KYC Policy</strong>, users must
            provide government-issued identification and proof of address. This helps
            ensure that PropNerd maintains a secure and legally compliant environment for
            all investors and issuers.
          </p>
          <ul>
            <li>
              <p>
                <strong>Identity Verification</strong>: We utilise third-party
                verification services to check users' IDs, ensuring they comply with local
                and international financial regulations.
              </p>
            </li>
            <li>
              <p>
                <strong>Security of Information</strong>: All personal data is handled
                securely and stored in compliance with data protection laws, such as GDPR.
              </p>
            </li>
          </ul>
          <h3>AML (Anti-Money Laundering) Policy</h3>
          <p>
            PropNerd has implemented rigorous&nbsp;
            <strong>Anti-Money Laundering (AML) procedures</strong> to monitor, detect,
            and report any suspicious activity. We aim to prevent illegal activities such
            as money laundering or terrorist financing by enforcing robust financial
            monitoring and reporting protocols.
          </p>
          <ul>
            <li>
              <p>
                <strong>Transaction Monitoring</strong>: We actively track and review all
                financial activities on our platform for any unusual or high-risk
                transactions.
              </p>
            </li>
            <li>
              <p>
                <strong>Reporting</strong>: Any suspicious behaviour is immediately
                flagged and reported to the appropriate authorities in line with AML
                regulations.
              </p>
            </li>
          </ul>
          <h3>Disclaimer</h3>
          <p>
            PropNerd is not a broker-dealer, and all investments are subject to regulatory
            review. Investors are required to undergo identity verification through KYC
            processes and comply with AML laws. Failure to do so may result in account
            restrictions. This process is essential to ensure the security and legality of
            all transactions on the platform.
          </p>
          <h3>Investor Protection Measures</h3>
          <p>
            PropNerd is committed to ensuring the safety of investor funds through the
            following mechanisms:
          </p>
          <ul>
            <li>
              <p>
                <strong>Third-Party Custody</strong>: In certain cases, funds are held in&nbsp;
                <strong>escrow accounts</strong> or secured by&nbsp;
                <strong>third-party custodians</strong>, offering additional layers of
                protection.
              </p>
            </li>
            <li>
              <p>
                <strong>Transparency</strong>: Every transaction and investment
                opportunity is fully transparent, providing investors with clear
                information before they make decisions.
              </p>
            </li>
          </ul>
          <h3>Legal Framework and Compliance</h3>
          <p>
            PropNerd operates in compliance with all relevant laws and regulations,
            including:
          </p>
          <ul>
            <li>
              <p>
                <strong>UK Financial Conduct Authority (FCA) regulations</strong> for
                investment platforms
              </p>
            </li>
            <li>
              <p>
                <strong>European Union (EU) financial regulations</strong> for investor
                protection
              </p>
            </li>
            <li>
              <p>
                <strong>Anti-Money Laundering Directives (AMLDs)</strong> for the
                prevention of illicit activities
              </p>
            </li>
          </ul>
          <h3>Terms of Service and Legal Disclaimers</h3>
          <p>
            By using PropNerd, all users agree to our&nbsp;
            <strong><Link to={AppRoute.Terms}>Terms of Service</Link></strong> and <strong><Link to={AppRoute.Disclaimer}>Legal Disclaimers</Link></strong>,
            which outline the legal responsibilities of both PropNerd and our users. We
            recommend all users familiarise themselves with these terms to understand
            their rights and obligations.
          </p>

        </div>
      </Content>

      <Footer />
    </>

  );
}