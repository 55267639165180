import { Content } from "../../components/content/content";
import { Footer } from "../../components/footer/footer";
import { Nav } from "../../components/nav/nav";

import { Helmet } from "react-helmet";
import { Bkg } from "../../components/content/bkg";

export function DisclaimerPage() {
  return (
    <>
      <Helmet>
        <title>PropNerd | Disclaimer</title>
        <meta
          name="description"
          content="Disclaimer for PropNerd. Please read the terms of service and disclaimer before using the website."
        />
      </Helmet>

      <Nav />

      <Content className="static">
        <h2>Disclaimer</h2>

        <div className="text-content">
          
          <h3>1. TERMS OF SERVICE</h3>
          <p>WELCOME TO PROPNERD&trade; ("PROPNERD&trade;," "WE," "US" OR "OUR") WEBSITE/APP (WWW.PROPNERD.IO), WHICH INCLUDES A SOFTWARE AS A SERVICE PLATFORM FOR ONBOARDING ISSUERS AND INVESTORS AND PROVIDING ONLINE TOOLS FOR THE OFFERING AND SALE OF PRIVATE INVESTMENT OPPORTUNITIES (COLLECTIVELY, THE "PROPNERD&trade; PLATFORM").</p>
          <p>THIS TERMS OF SERVICE AND OTHER AGREEMENTS ON THE PROPNERD&trade; PLATFORM GOVERN YOUR USE OF THE PROPNERD&trade; PLATFORM, AND ALSO INCLUDE IMPORTANT DISCLOSURES AND REGULATORY INFORMATION THAT ARE ASSOCIATED WITH THE SERVICES AVAILABLE ON THE PROPNERD&trade; PLATFORM, ALL OF WHICH ARE IMPORTANT AND AFFECT YOUR LEGAL RIGHTS, SO PLEASE READ IT CAREFULLY.</p>
          <p>BY CLICKING ON THE "CONTINUE" BUTTON, COMPLETING THE ACCOUNT REGISTRATION PROCESS, OR USING OUR SERVICES, YOU REPRESENT THAT (A) YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THE TERMS OF USE, (B) YOU ARE OF LEGAL AGE TO FORM A BINDING CONTRACT WITH PROPNERD&trade;, AND (C) YOU HAVE THE AUTHORITY TO ENTER INTO THE TERMS OF USE PERSONALLY OR ON BEHALF OF THE ENTITY YOU HAVE NAMED AS THE REGISTERED USER, AND TO BIND THAT ENTITY TO THE TERMS OF USE. THE TERM "YOU" REFERS TO THE INDIVIDUAL OR LEGAL ENTITY, AS APPLICABLE, IDENTIFIED AS THE REGISTERED USER WHEN YOU SUBMIT YOUR REGISTRATION INFORMATION ON THE PROPNERD&trade; PLATFORM. IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS OF USE, YOU MAY NOT ACCESS OR USE THE PROPNERD&trade; PLATFORM.</p>
          <p>PropNerd is not a broker-dealer, and all investments are subject to regulatory review. Investors are required to undergo identity verification through KYC processes and comply with AML laws. Failure to do so may result in account restrictions. This process is essential to ensure the security and legality of all transactions on the platform.</p>
          <p>Your use of, and participation in, certain Services may be subject to additional terms ("Supplemental Terms"), and such Supplemental Terms will either be listed in the Terms of Use or will be presented to you for your acceptance when you sign up to use the supplemental Service.</p>
          <p>If the Terms of Use are inconsistent with the Supplemental Terms, the Supplemental Terms shall control with respect to such Service. The Terms of Use and any applicable Supplemental Terms are referred to herein as the "Agreement".</p>
          
          <h3>2. SCOPE OF SERVICES</h3>
          <p><b>2.1 Services Provided:</b> The PropNerd&trade; platform offers tools and services to investors ("Investors") and issuers ("Issuers") for private investment opportunities. This includes the offering, sale, and management of investments, represented by cryptographic digital tokens ("Tokens") stored securely on the blockchain.</p>
          <p><b>2.2 Investment Participation:</b> Participation in any offering through the PropNerd&trade; platform is subject to your agreement with the specific terms, conditions, and risk factors associated with each offering. You must review all relevant documents before deciding to invest. Additional documentation and consent may be required for certain offerings.</p>
          <p><b>2.3 Investment Risks:</b> Tokens offered on the PropNerd&trade; platform are highly speculative and involve a high degree of risk. These investments are not insured, and there is no guarantee that a secondary market will develop. Tokens are not publicly traded, and liquidation may be difficult. By investing, you acknowledge the risk of losing your entire investment.</p>
          
          <h3>3. PRIVACY POLICY</h3>
          <p>Please refer to our Privacy Policy for detailed information on how we collect, use, and share your personal information. By using the PropNerd&trade; Platform, you consent to the collection and use of your information as described in the Privacy Policy.</p>
          
          <h3>4. ACCOUNT REGISTRATION AND COMMUNICATION PREFERENCES</h3>
          <p><b>4.1 Account Creation:</b> To access our services, you must create an account ("Account") and become a registered user ("Registered User"). You agree to provide accurate and current information and maintain the security of your password. You are responsible for all activities that occur under your Account.</p>
          <p><b>4.2 Verification Checks:</b> We or our third-party vendors may conduct identity verification and compliance checks as required by law. These checks may determine your eligibility to use our services, and we may re-confirm these checks at our discretion.</p>
          <p><b>4.3 Communication Preferences:</b> By creating an Account, you consent to receive electronic communications from PropNerd&trade;, including notices about your Account and promotional materials. You may opt out of promotional emails, but you cannot opt out of essential communications related to your Account.</p>
          
          <h3>5. OWNERSHIP AND INTELLECTUAL PROPERTY</h3>
          <p><b>5.1 Intellectual Property:</b> The PropNerd&trade; platform and all content within it, including logos, designs, text, and software, are the proprietary property of PropNerd&trade; or our affiliates. You are granted a limited, non-exclusive license to access and use the platform for personal or internal business purposes only.</p>
          <p><b>5.2 Restrictions:</b> You may not copy, modify, distribute, or otherwise exploit any content on the PropNerd&trade; platform without our prior written permission. Unauthorized use of our content will result in the termination of your license to use the platform and may result in legal action.</p>
          
          <h3>6. AUTHORIZED ACCESS AND USE OF THE PROPNERD&trade; PLATFORM</h3>
          <p>You are granted a limited, non-exclusive, non-transferable, non-sublicensable license to access and use the PropNerd&trade; Platform for your personal or internal business purposes. Any unauthorized use of the platform, including but not limited to commercial exploitation, reverse engineering, or data scraping, is strictly prohibited.</p>
          
          <h3>7. USER CONDUCT AND FEEDBACK</h3>
          <p><b>7.1 User Conduct:</b> You agree that your use of the PropNerd&trade; Platform will not violate any law, contract, intellectual property or other third-party rights, and that you are solely responsible for your conduct while using the platform.</p>
          <p><b>7.2 Feedback:</b> By submitting feedback, you grant PropNerd&trade; a fully paid, royalty-free, perpetual, irrevocable, worldwide, non-exclusive, and fully sublicensable right to use, reproduce, perform, display, distribute, and otherwise commercially or non-commercially exploit such feedback.</p>
          
          <h3>8. CONFIDENTIALITY</h3>
          <p>As a Registered User, you may receive confidential information from PropNerd&trade; or an Issuer. You are required to maintain the confidentiality of this information and may not disclose it to any third party without express permission.</p>
          
          <h3>9. INDEMNIFICATION</h3>
          <p>To the fullest extent permitted by applicable law, you agree to indemnify, defend, and hold harmless PropNerd&trade;, and our respective past, present, and future employees, officers, directors, consultants, equity holders, suppliers, vendors, service providers, parent companies, subsidiaries, affiliates, agents, representatives, predecessors, successors, and assigns (individually and collectively, the "PropNerd&trade; Parties"), from and against all actual or alleged third-party claims, damages, awards, judgments, losses, liabilities, obligations, penalties, interest, fees, expenses (including, without limitation, attorneys' fees and expenses) and costs (including, without limitation, court costs, costs of settlement, and costs of pursuing indemnification and insurance), of every kind and nature whatsoever, whether known or unknown, foreseen or unforeseen, matured or unmatured, or suspected or unsuspected, in law or equity, whether in tort, contract or otherwise (collectively, "Claims"), including, but not limited to, damages to property or personal injury, that are caused by, arise out of, or are related to (a) your use or misuse of the PropNerd&trade; Platform, (b) your violation of this Agreement, or (c) your negligence or willful misconduct.</p>
          
          <h3>10. LIMITATION OF LIABILITY</h3>
          <p><b>10.1 General Limitation:</b> To the fullest extent permitted by law, PropNerd&trade; shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the use of the PropNerd&trade; platform or any associated services. This includes, but is not limited to, damages for loss of profits, goodwill, or data, even if we have been advised of the possibility of such damages.</p>
          <p><b>10.2 Total Liability:</b> Notwithstanding anything to the contrary, PropNerd&trade;'s maximum aggregate liability to you arising out of or in any way related to this Agreement or the PropNerd&trade; platform shall not exceed the total amount you have paid to PropNerd&trade; for accessing the platform and using the services.</p>
          
          <h3>11. DISPUTE RESOLUTION; ARBITRATION</h3>
          <p><b>11.1 Arbitration Requirement:</b> Any dispute or claim arising out of or relating to your use of the PropNerd&trade; platform, any products or services sold through the platform, or this Agreement shall be resolved through binding arbitration, not in court. By agreeing to this Agreement, you waive your right to a jury trial and agree to resolve any disputes through arbitration.</p>
          <p><b>11.2 Individual Basis:</b> All disputes must be brought on an individual basis. Neither you nor PropNerd&trade; shall have the right to bring any claim as a plaintiff or class member in any purported class or representative action.</p>
          <p><b>11.3 Arbitration Process:</b> The arbitration shall be conducted in English in the United Kingdom and administered by an established arbitration organisation agreed upon by both parties. The arbitrator's decision shall be final and binding. You may choose to have the arbitration conducted by teleconference, based on written submissions, or in person.</p>
          <p><b>11.4 Authority of Arbitrator:</b> The arbitrator shall have exclusive authority to resolve any dispute related to the interpretation, applicability, enforceability, or formation of this Arbitration Agreement. The arbitrator shall issue a written decision that includes the essential findings and conclusions on which the award is based.</p>
          
          <h3>12. GOVERNING LAW</h3>
          <p>This Agreement, including your access to and use of the Services made available on the PropNerd&trade; Platform, shall be governed by and construed and enforced in accordance with the laws of the United Kingdom (without regard to conflict of law rules or principles).</p>
          
          <h3>13. TERMINATION</h3>
          <p><b>13.1 Termination Rights:</b> PropNerd&trade; reserves the right to terminate your access to the platform at any time, with or without cause. Upon termination, you must cease all use of the platform and any content obtained through it.</p>
          <p><b>13.2 Post-Termination Obligations:</b> If your Account is terminated, any investments associated with your Account must be transferred to a third party or a designated wallet. We are not responsible for any losses or delays incurred during this process.</p>
          
          <h3>14. ASSIGNMENT</h3>
          <p><b>14.1 Assignment by PropNerd&trade;:</b> PropNerd&trade; may assign or transfer its rights and obligations under this Agreement at any time, without your consent.</p>
          <p><b>14.2 Assignment by User:</b> You may not assign or transfer your rights or obligations under this Agreement without the prior written consent of PropNerd&trade;. Any attempted assignment or transfer without such consent will be null and void.</p>
          
          <h3>15. MODIFICATIONS TO THE PROPNERD&trade; PLATFORM</h3>
          <p>PropNerd&trade; reserves the right to modify, suspend, or discontinue any part of its platform at any time, with or without notice. Users will be notified of significant changes, and continued use of the platform constitutes acceptance of the revised terms.</p>
          
          <h3>16. NO INVESTMENT OR FINANCIAL ADVICE</h3>
          <p>PropNerd&trade; does not provide investment, legal, or financial advice. Users are solely responsible for their investment decisions and should consult with independent professionals before making any investments.</p>
          
          <h3>17. LINKING TO THIRD-PARTY WEBSITES</h3>
          <p>The PropNerd&trade; Platform may include links to third-party websites or services. PropNerd&trade; is not responsible for the content, privacy practices, or operations of these third parties.</p>
          
          <h3>18. MISCELLANEOUS</h3>
          <p><b>18.1 Entire Agreement:</b> This Agreement constitutes the entire agreement between you and PropNerd&trade; regarding your use of the platform and supersedes all prior agreements, understandings, or representations.</p>
          <p><b>18.2 Severability:</b> If any provision of this Agreement is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.</p>
          <p><b>18.3 Waiver:</b> The failure of PropNerd&trade; to enforce any right or provision of this Agreement will not be deemed a waiver of such right or provision.</p>
          
          <h3>19. SURVIVAL</h3>
          <p>Certain obligations and rights of the parties shall survive the termination of this Agreement, including but not limited to confidentiality, indemnification, and limitation of liability.</p>
          
          <h3>20. SEVERABILITY</h3>
          <p>If any term, clause, or provision of this Agreement is held invalid or unenforceable, then that term, clause, or provision will be severable from this Agreement and will not affect the validity or enforceability of any remaining part of that term, clause, or provision, or any other term, clause, or provision of this Agreement.</p>
          
          <h3>21. KEY RISKS</h3>
          <h4>General Investment Risks</h4>
          <p>Investing through PropNerd&trade; involves significant risks, including the potential for loss of capital, limited liquidity, and reliance on blockchain technology. As with any investment, the value of your investment may fluctuate, and anticipated returns may not be realised. The market value of real estate assets can vary due to economic, political, and social factors, which may negatively impact your investment. Furthermore, delays in receiving rental income or difficulties in selling the property at the end of the investment period could result in lower-than-expected returns.</p>
          <h4>Blockchain-Related Risks</h4>
          <p>By using the PropNerd&trade; Platform, you acknowledge and accept the inherent risks associated with blockchain-based tokens, including technical failures, security vulnerabilities, and regulatory changes. Blockchain technology is still evolving, and there is a risk of technological failures, such as network congestion or smart contract bugs, which could adversely impact your investments. Additionally, the security of blockchain systems is not infallible, and there is always the risk of security breaches that could result in the loss of your assets.</p>
          <h4>Liquidity Risks</h4>
          <p>PropNerd&trade; currently does not offer a Secondary Transfer Facility (referred to as an "Exit Window"). While PropNerd&trade; is actively working towards launching a secondary resale platform in the future, this service is not yet available, so you may be unable to sell your tokens quickly. Although you can sell your tokens to a third party at any time, as outlined in the Assignment section, there is no guarantee that a buyer will be available, making your investment potentially illiquid.</p>
          <h4>Regulatory Risks</h4>
          <p>The regulatory environment for blockchain technology and digital assets is rapidly changing. Future legal or regulatory actions could impact the operation of the PropNerd&trade; Platform or the value and transferability of the tokens you hold.</p>
          <h4>No Guarantee</h4>
          <p>Past performance is no guarantee of future returns. Any historical performance, expected returns, or projected figures may not result in actual future performance. Your capital is at risk and is not guaranteed.</p>
          <h4>Investment Period and Liquidity</h4>
          <p>Investments through PropNerd&trade; are intended to be held for the full duration of the recommended Investment Period, which is 5 years. While PropNerd&trade; aims to eventually offer a Secondary Transfer Facility (referred to as an "Exit Window") in the future, such a service is currently not available. However, you are free to sell your investment to a third party at any time should you choose to do so, in accordance with the terms laid out in the Assignment section of this document. Please note that there is no guarantee that you will be able to sell your investment, and as such, your investment should be considered illiquid.</p>
          <h4>Risk Warnings and Disclosures</h4>
          <p>Investing in real estate through PropNerd&trade; is speculative and involves significant risk. Investors will hold shares in a private special purpose vehicle (SPV) that owns the property, rather than directly owning the property itself. The value of your investment may fluctuate, and anticipated returns may not be realised. Additionally, there may be delays in receiving rental income, or challenges in selling the property at the end of the investment period. Some jurisdictions may have restrictions on property sales to foreign owners, which could limit potential buyers. PropNerd&trade; investments differ materially from direct property ownership, real estate funds, REITs, or other securities. If PropNerd&trade; ceases operations, you may experience losses, incur costs, and/or face delays in receiving payouts. Using borrowed funds to invest carries additional risk, as you will be required to meet repayment obligations even if the value of your investment declines.</p>
          <h4>Disclaimers</h4>
          <p>Investing in property carries risks, and your capital may be at risk. There is no guarantee that you will achieve the expected returns. The real estate market is subject to fluctuations, and there is no assurance of rental income or property value appreciation. Predictions and historical results should not be relied upon as accurate indicators of future performance. Market downturns can impact the value of your investment.</p>
          <h4>No Advice</h4>
          <p>PropNerd&trade; operates as a real estate investment platform and administers private vehicles holding property on behalf of investors. PropNerd&trade; is not an investment advisor, nor does it provide property management services. We do not offer investment, legal, or financial advice. Investors are responsible for conducting their own due diligence or consulting with independent financial advisors before making investment decisions. While we use data from third-party sources that we believe to be reliable, we cannot guarantee the accuracy or completeness of this information.</p>
          <h4>Other Disclaimers</h4>
          <p>This information is for informational purposes only and does not constitute investment advice. Estimates provided are based on past performance and current market conditions, which may not accurately predict future results. The data and projections included may be subject to change, and additional factors could cause actual results to differ materially. This is not an offer or solicitation in any jurisdiction, and it does not constitute regulated financial advice. Please consult with an independent financial advisor before making any investment decisions.</p>
        </div>
      </Content>

      <Footer />
    </>

  );
}