import React from 'react';
import InformationIcon from '../../assets/img/common/information.png'; // Ensure this path is correct
import '../../assets/styles/modal/under-maintenance.css';
import { Modal } from './modal';

interface UnderMaintenanceProps {
    open?: boolean; // Modal open state
    errorType?: string; // Error type to determine content
    onClose: () => void; // Function to close the modal
}

export function UnderMaintenance({ open, errorType, onClose }: UnderMaintenanceProps) {
    const isUnderMaintenance = errorType === 'UNDER_MAINTENANCE';

    if (!isUnderMaintenance) return null; // Do not render the modal if it's not open

    return (
        <Modal onClose={onClose} className='modal-bg'>
            <div className="modal-header">
                <div className="icon-wrapper">
                    <img src={InformationIcon} alt="Wait" className="icon" />
                </div>
                <h3 className="modal-title">System Temporarily<br />Unavailable</h3>
            </div>

            {/* Divider */}
            <div className="divider" />

            <p className="modal-content">
                Our system is currently <strong className='highlight'>down for maintenance.</strong> Our team has been duly informed
                about the action you intended. If required, <strong className='highlight'>you will be notified</strong> by email when there's
                an update for you. Thank you for understanding.
            </p>

            {/* Divider */}
            <div className="divider" />

            {/* Close or Continue button */}
            <button
                onClick={onClose}
                className="modal-button"
            >
                {isUnderMaintenance ? 'Continue' : 'Close'}
            </button>
        </Modal>
    );
}

export default UnderMaintenance;
