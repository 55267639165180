import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Nav } from '../../../components/nav/nav';
import { Bkg } from '../../../components/content/bkg';
import { Content } from '../../../components/content/content';
import { Footer } from '../../../components/footer/footer';
import { useMutation } from '@apollo/client';
import { MUTATION_ACCOUNT_DELETION_REQUEST } from '../../../graphql/mutations/account-deletion';
import UnderMaintenance from '../../../components/modal/under-maintenance';

const AccountRequestDeletionPage = () => {
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState('');
    const [requestAccountDeletion, { loading: requestAccountDeletionLoading, error: deletionReqError }] = useMutation(MUTATION_ACCOUNT_DELETION_REQUEST, { errorPolicy: 'all', fetchPolicy: 'no-cache' });

    const validateEmail = (email: string) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setErrorMessage('');
        setSuccess('');

        if (!validateEmail(email)) {
            setErrorMessage('Please enter a valid email address.');
            return;
        }

        try {
            const { data } = await requestAccountDeletion({
                variables: { email },
            });

            console.log(data);

            if (data?.requestAccountDeletion) {
                setSuccess('Your deletion request is in process. You will be contacted for verification by our support team within 72 hours.');
                setEmail('');
            } else {
                setErrorMessage(deletionReqError?.message || '');
            }
        } catch (err) {
            setErrorMessage('Network error. Please try again.');
        }
    };

    const handleCloseUnderMaintenance = () => {
        setErrorMessage('');
    }

    return (
        <>
            <Helmet>
                <title>PropNerd | Request Account Deletion</title>
                <meta
                    name="description"
                    content="Request the deletion of your PropNerd account. Your account will be permanently deleted and all data will be removed."
                />
            </Helmet>

            <UnderMaintenance
                errorType={errorMessage}
                onClose={handleCloseUnderMaintenance}
            />

            <Nav />

            <Content className="flex account flex-column">
                <h2>
                    Request Account Deletion
                </h2>
                <p>
                    Enter your email address to request the deletion of your PropNerd account. Your account will be permanently deleted and all data will be removed.
                </p>
                <form className="flex flex-1 mb-5 flex-column gap" onSubmit={handleSubmit}>

                    <div className="flex flex-column">
                        <label htmlFor="email">Email Address</label>
                        <input
                            type="text"
                            id="email"
                            name="email"
                            value={email}
                            placeholder="Email Address"
                            autoFocus
                            onChange={(e) => setEmail(e.currentTarget.value)}
                        />
                    </div>
                    {errorMessage && errorMessage !== 'UNDER_MAINTENANCE' && <p className="error-message">{errorMessage}</p>}
                    {success && <p className="info-message">{success}</p>}
                    <div className="actions">
                        <button type="submit" className="btn btn-primary btn-square" disabled={requestAccountDeletionLoading}>
                            {requestAccountDeletionLoading ? 'Loading...' : 'Submit Request'}
                        </button>
                    </div>
                </form>
            </Content>

            <Footer />
        </>
    );
};

export default AccountRequestDeletionPage;
