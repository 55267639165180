import { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { AppRoute } from "../../routes";
import ImgLogo from './../../assets/img/common/logo.svg';

import classNames from "classnames";
import { ReactComponent as ImgArrow } from '../../assets/img/common/arrow.svg';
import { ReactComponent as ImgLang } from '../../assets/img/common/lang.svg';
import { ReactComponent as ImgMobileMenu } from '../../assets/img/common/mobile-menu.svg';
import { useAnalytics } from "../../hooks/use-analytics.hook";

export function Nav() {

  const [isPropertyMenuOpen, setIsPropertyMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const location = useLocation();

  useAnalytics();

  return (

    <>

      <div className="nav-disclaimer">
      Don't invest unless you're prepared to lose all the money you invest. This is a high-risk investment, and you are unlikely to be protected if something goes wrong. <Link to={AppRoute.Risk}>Take 2 mins to learn more</Link>
      </div>
      
      <nav>
        
        <div className="content">
          <a href="#mobile-menu" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}><ImgMobileMenu className="mobile-menu" width={30} height={30}  aria-label="Menu"/></a>

          <NavLink to={ AppRoute.Home }>
            <img src={ ImgLogo } alt="PropNerd" width={200} height={41} />
          </NavLink>

          <div className={classNames('main-links', isMobileMenuOpen ? 'open' : '')}>
            <NavLink to={ AppRoute.About }>About</NavLink>
            <NavLink to={ AppRoute.HowItWorks }>How it works</NavLink>
            <div className={`btn-dropdown ${[AppRoute.PropertyMarketplace, AppRoute.PropertySubmit].includes(location.pathname as AppRoute) ? ' active' : ''}`} onClick={() => setIsPropertyMenuOpen(!isPropertyMenuOpen)}>
              <a href="#properties">Properties <ImgArrow className="arrow" /></a>
              <div className="dropdown-links">
                <NavLink to={ AppRoute.PropertyMarketplace }>Property marketplace</NavLink>
                <NavLink to={ AppRoute.PropertySubmit }>Submit property</NavLink>
              </div>
            </div>
            <NavLink to={ AppRoute.Services }>Services</NavLink>
            <a href={ process.env.REACT_APP_BLOG_URL }>LearnHub</a>
            <NavLink to={ AppRoute.Faq }>FAQs</NavLink>
            <a href="https://investor.propnerd.io" target="_blank" rel="noreferrer" className="d-hidden">Login</a>
          </div>

          {isMobileMenuOpen && <div className="overlay" onClick={() => setIsMobileMenuOpen(false)}></div>}

          <div className="account-links flex gap">
            <div className="btn-dropdown">
              { /*    <div className="dropdown-links">
              <ImgLang /> <span>EN</span> <ImgArrow className="arrow" />
                <a href="#lang-en" className="active">EN</a>
                {/* <a href="#lang-fr">FR</a>
                <a href="#lang-es">ES</a> 
              </div>
            */}
            </div>
            <a href="https://investor.propnerd.io" target="_blank" rel="noreferrer" className="btn btn-white btn-square m-hidden">Login</a>
            <Link to={ AppRoute.AccountRegister } className="btn btn-square">Sign Up</Link>
          </div>
        </div>

      </nav>
    </>
  )
}