import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/navigation";

// Import required modules
import SwiperCore from 'swiper';
import { Autoplay, Pagination } from 'swiper/modules';

import StarSvg from '../../../assets/svgs/rating.svg';
import ImgTestimonial1 from '../../../assets/img/home/testimonials/testimonial_1.png';
import ImgTestimonial2 from '../../../assets/img/home/testimonials/testimonial_2.jpg';
import ImgTestimonial3 from '../../../assets/img/home/testimonials/testimonial_3.png';
import ImgTestimonial4 from '../../../assets/img/home/testimonials/testimonial_4.jpg';
import ImgTestimonial5 from '../../../assets/img/home/testimonials/testimonial_5.jpg';

// Install modules
SwiperCore.use([Pagination]);

const HomeTestimonialSlider = () => {
    const testimonials = [
        {
            name: "Olivia F.",
            date: "Manchester, UK",
            review: "PropNerd has made investing in property easier than I thought possible. The platform is transparent and intuitive, and I'm excited to see how it grows.",
            rating: 5,
            image: ImgTestimonial1,
        },
        {
            name: "Nathan R.",
            date: "Leeds, UK",
            review: "I've been using PropNerd for a couple of months now, and while it's still a young platform, it's been smooth and easy to use. The potential for growth is really exciting.",
            rating: 5,
            image: ImgTestimonial2,
        },
        {
            name: "Sophie P.",
            date: "Liverpool, UK",
            review: "The potential of PropNerd is what really drew me in. The platform is easy to navigate, and I can clearly track my investments, making it a great way to diversify.",
            rating: 5,
            image: ImgTestimonial3,
        },
        {
            name: "Jonathan L.",
            date: "London, UK",
            review: "As an experienced investor, I'm impressed by the transparency of PropNerd. The ability to see exactly where your funds are allocated and track returns is crucial, and PropNerd delivers that level of detail.",
            rating: 5,
            image: ImgTestimonial4,
        },
        {
            name: "Paul R.",
            date: "Ireland",
            review: "I've been in property investment for over a decade, and PropNerd offers a new and innovative approach. The fractional investment model is ideal for diversifying, and I can see this platform scaling rapidly.",
            rating: 5,
            image: ImgTestimonial5,
        },
    ];

    return (
        <div className="testimonial-slider">
            <h2>See What Our Investors Have To Say</h2>
            <Swiper
                autoplay={{ delay: 8000 }}
                modules={[Autoplay]}
                spaceBetween={30}
                pagination={{ clickable: true }}
                loop={true}
                breakpoints={{
                    // when window width is >= 768px
                    768: {
                        slidesPerView: 2,
                    },
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 1,
                    },
                }}
            >
                {testimonials.map((testimonial, index) => (
                    <SwiperSlide key={index}>
                        <div className="testimonial">
                            <p>“{testimonial.review}”</p>
                            <div className="testimonial-info">
                                <div className="testimonial-user">
                                    <img src={testimonial.image} alt={testimonial.name} className="testimonial-image" />
                                    <div className="testimonial-details">
                                        <h4>{testimonial.name}</h4>
                                        <div className="rating">
                                            {[...Array(testimonial.rating)].map((_, i) => (
                                                <img src={StarSvg} key={i} alt="rating" />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <p>{testimonial.date}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default HomeTestimonialSlider;
