import Intercom, { show } from '@intercom/messenger-js-sdk';
import { useEffect, useState } from 'react';
import { ReactComponent as BlueLogo } from '../../assets/img/logo/blue-logo-without-text.svg';
import { ReactComponent as YellowLogo } from '../../assets/img/logo/yellow-logo-without-text.svg';
import '../../assets/styles/intercom.css';

interface Props {
  type?: 'yellow' | 'blue';
}

export function IntercomButton({ type = 'yellow' }: Props) {
  const Logo = type === 'blue' ? BlueLogo : YellowLogo;

  useEffect(() => {
    Intercom({
      app_id: 'onnd9j9n',
      "hide_default_launcher": true
    });
  }, [])


  return (
    <div
      className={`intercom-button-container ${type}`}
      onClick={show}
    >
      <Logo width="24px" style={{ display: 'block' }} />
    </div>
  );
}
