import Intercom from "@intercom/messenger-js-sdk";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppRoute } from "../../routes";

interface Props {
  onAccept: () => void;
}

export function CookieBar({ onAccept }: Props) {

  const [showConsentBar, setShowConsentBar] = useState(false);

  useEffect(() => {
    const consentCookie = document.cookie.split('; ').find(row => row.startsWith('cookie_consent='));
    
    if (!consentCookie) {
      setShowConsentBar(true);
    }

    if (consentCookie && consentCookie.includes('rejected')) {
      removeNonEssentialCookies();
      disableTrackingScripts();
    }

    if (consentCookie && consentCookie.includes('accepted')) {
      enableTrackingScripts();
      onAccept();
    }
  }, []);

  const accept = () => {
    // Set a consent cookie to remember the user's choice
    document.cookie = `cookie_consent=accepted; path=/; max-age=${365 * 24 * 60 * 60}; SameSite=Lax`;
    enableTrackingScripts();
    setShowConsentBar(false);
    setShowConsentBar(false);

    onAccept();
  };

  const reject = () => {
    // Set a consent cookie to remember the user's choice
    document.cookie = `cookie_consent=rejected; path=/; max-age=${365 * 24 * 60 * 60}; SameSite=Lax`;

    removeNonEssentialCookies();
    disableTrackingScripts();
    window.location.reload();
  };

  const removeNonEssentialCookies = () => {
    // List of non-essential cookies to delete
    const nonEssentialCookies = [
      '__hstc', '_fbp', '_hjSessionUser_5187160', '_vwo_uuid_v2', 'hubspotutk', 
      'intercom-device-id-onnd9j9n', 'intercom-id-onnd9j9n', 'intercom-session-onnd9j9n'
    ];

    nonEssentialCookies.forEach(cookieName => {
      document.cookie = `${cookieName}=; path=/; max-age=0; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Lax`;
    });
  }

  const enableTrackingScripts = () => {
    (window as any)[`ga-disable-${process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID}`] = false;
    (window as any)['__hs_do_not_track'] = false;
  };

  const disableTrackingScripts = () => {
    (window as any)[`ga-disable-${process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID}`] = true;
    (window as any)['__hs_do_not_track'] = true;
  };

  return showConsentBar && (
    <div className="cookie-bar">
      <div className='cookie-bar-content'>
        <p>By clicking <b>"Accept"</b> you agree to the storing of all cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts. View our <Link to={AppRoute.Cookie} target='_blank'>Cookie Policy</Link> for more information.</p>
        <div className='cookie-bar-buttons'>
          {/* <button className='btn btn-link'>Preferences</button> */}
          <button className='btn btn-white' onClick={reject}>Reject</button>
          <button className='btn btn-yellow' onClick={accept}>Accept</button>
        </div>
      </div>
    </div>
  );
}