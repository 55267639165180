import { PropsWithChildren, useEffect } from "react";
import '../../assets/styles/modal/modal.css';

interface Props extends PropsWithChildren {
    title?: string;
    onClose?: () => void;
    className?: string;
}

export function Modal({ title, onClose, className, children }: Props) {
    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                onClose && onClose();
            }
        };
        window.addEventListener("keydown", handleEsc);

        return () => {
            window.removeEventListener("keydown", handleEsc);
        };
    }, []);

    return (
        <>
            <div
                className="modal-backdrop"
                onClick={() => onClose && onClose()}
            ></div>
            <div className={`modal ${className || ""}`}>
                {title && (
                    <div className={`modal-header ${className || ""}`}>
                        <div className="modal-title">{title}</div>
                        <div
                            className="modal-close"
                            onClick={() => onClose && onClose()}
                        >
                            x
                        </div>
                    </div>
                )}
                <div className="modal-content">{children}</div>
            </div>
        </>
    );
}
